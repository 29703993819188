import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import { useAppConfiguration } from 'src/hooks/use-app-configuration';
import LogoutIcon from 'src/assets/icons/logout.svg?react';
import { useLogout } from 'src/apis/authentication-api';
import { useStyles } from './styles';
import navigationItems from '../navigation-items';
import LanguageSelect from '../language-select';

interface MenuButtonProps {
  Icon: React.ElementType;
  label: string;
  active: boolean;
  path: string;
}

const MenuButton = ({ Icon, label, active, path }: MenuButtonProps) => {
  const { classes } = useStyles({ active });
  const newBooking = !!localStorage.getItem('HAS_NEW_BOOKING');

  return (
    <Box className={classes.menuButton}>
      <IconButton
        className={clsx(
          classes.menuIcon,
          path === '/bookings' && active === false && newBooking && classes.new,
        )}
        size="medium"
      >
        <Icon />
      </IconButton>
      <Typography className={classes.menuLabel} variant="subtitle1">
        {label}
      </Typography>
    </Box>
  );
};

interface MenuMobileProps {
  setIsMenuOpen: (value: boolean) => void;
}

const MenuMobile = ({ setIsMenuOpen }: MenuMobileProps) => {
  const { classes } = useStyles({});
  const { t } = useTranslation();
  const location = useLocation();
  const { withSideNavigation } = useAppConfiguration();
  const logout = useLogout();

  return (
    <>
      {withSideNavigation && (
        <Box display="table" width="100%" height="80%">
          <Box display="table-row">
            <Box
              display="table-cell"
              height="100%"
              onClick={() => setIsMenuOpen(false)}
            >
              {navigationItems.map((item) => (
                <Box className={classes.menuItem} key={item.title}>
                  <NavLink className={classes.menuLink} to={item.path}>
                    <MenuButton
                      active={location.pathname === item.path}
                      label={t(item.title)}
                      Icon={item.icon}
                      hasNewContent={item.hasNewContent}
                      path={item.path}
                    />
                  </NavLink>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.menuMobileBottom}>
        <LanguageSelect />
      </Box>
      {withSideNavigation && (
        <Box className={classes.menuMobileBottom}>
          <NavLink
            className={classes.menuLink}
            activeClassName={classes.menuLinkActive}
            onClick={logout}
            title={t('Menu.logOut')}
          >
            <MenuButton label={t('Menu.logOut')} Icon={LogoutIcon} />
          </NavLink>
        </Box>
      )}
    </>
  );
};

export default MenuMobile;
